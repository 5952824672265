.simulation-container {
  text-align: center;
  padding: 2em;
}

.simulation-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.simulation-button {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  background-color: var(--background-color);
  box-shadow: var(--shadow);
  color: var(--text-color);
  border: none;
  border-radius: 0px;
  transition: background-color 0.3s ease;
}

.simulation-button:hover {
  color: var(--clr-bg);
  background-color: var(--clr-fg-alt);
}

.simulation-container h2 {
  margin-bottom: 20px;
}

.chart-container {
  margin-top: 20px;
}

.chart-container h3 {
  text-align: center;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

blockquote {
  border-left: 10px solid #ccc;
  margin: 1.5em;
  padding: 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

blockquote p {
  display: block;
  margin-top: 0.8em;
}

p {
  text-align: left;
}