.project {
  position: relative;
  padding: 2em;
  margin: 0 auto;
  text-align: center;
  box-shadow: var(--shadow);
  transition: transform 0.2s linear;
  overflow: hidden;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 250px;
  width: 350px;
}

.project::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--clr-primary);
  z-index: -1;
  transform: translateX(-100%);
  transition: transform 0.4s ease-in-out;
}

.project:hover::before {
  transform: translateX(0);
}

.project:hover {
  transform: translateY(-7px);
}

.project:hover h3,
.project:hover .project__description,
.project:hover .project__stack-item,
.project:hover .link--icon {
  color: var(--clr-bg) !important;
}

.project__description {
  margin-top: 1em;
  z-index: 1;
  position: relative;
  transition: color 0.4s ease-in-out;
  text-align: center;
}

.project__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.2em 0;
  z-index: 1;
  position: relative;
  transition: color 0.4s ease-in-out;
}

.project__stack-item {
  margin: 0.5em;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
  z-index: 1;
  position: relative;
  transition: color 0.4s ease-in-out;
}

.project .link--icon {
  margin-left: 0.5em;
  z-index: 1;
  position: relative;
  transition: color 0.4s ease-in-out;
}
