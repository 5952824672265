.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3em;
  text-align: center;
}

.about__name {
  color: var(--clr-primary);
}

.about__role {
  text-align: center;
  margin-top: 1.2em;
}

.about__desc {
  text-align: center;
  font-size: 1rem;
  max-width: 600px;
}

.about__desc,
.about__contact {
  margin-top: 2.4em;
}

.about .link--icon {
  margin-right: 0.8em;
}

.about .btn--outline {
  margin-right: 1em;
}

@media (max-width: 600px) {
  .about {
    margin-top: 2em;
  }
}