.footer {
  padding: 3em 0;
  margin-top: 4em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer__link {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--clr-fg);
}

.clock {
  font-size: 1.5em;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .footer {
    padding: 2em;
    margin-top: 3em;
  }
}
