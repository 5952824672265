.skills__list {
  max-width: 500px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills__list-item {
  margin: 0.5em;
  position: relative;
  padding: 1em;
  text-align: center;
  box-shadow: var(--shadow);
  color: var(--clr-fg-alt);
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
  background-color: var(--clr-bg);
}

.skills__list-item:hover {
  color: var(--clr-bg);
  background-color: var(--clr-primary);
}