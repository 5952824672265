.home-header {
  height: 8em;
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  .home-header {
    height: 6em;
  }
}
